.login {
  &-form {
    width: 700px;
    height: 700px;
    background: url(/assets/img/common/hexagon.svg) center/contain no-repeat;
    @media #{$sp} {
      width: 100%;
      height: auto;
      background: #fff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    }
  }
  &-btn {
    background: linear-gradient(180deg, #52B2BA 0%, #3C9DA5 100%);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
}