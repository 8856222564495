.fade-in {
  transition: opacity 0.3s, visibility 0s ease 0.3s;
  opacity: 0;
  visibility: hidden;
  &.is-show {
    transition-delay: 0s;
    opacity: 1;
    visibility: visible;
  }
}
#fixed-btn {
  @media #{$pc} {
    width: calc(100% - 180px);
  }
}