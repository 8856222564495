// Container

@mixin container {
  max-width: #{$container};
  position: relative;
  margin: auto;
  padding: 0 20px;
  @media #{$sp} {
    padding: 0 15px;
  }
}


// Clear fix （Bourbon には入っているけど、念のために）

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}


// Hover opacitiy for links ( just so they're all the same )

@mixin hoverlink {
  transition: 0.2s ease-in-out;
  backface-visibility: hidden;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &::before,
  &::after {
    backface-visibility: hidden;
  }
}

// Fontsize change by viewport

@function get_vw($size, $viewport:1394) {
  @return (100 / $viewport) * $size * 1vw;
}

@mixin fz_vw($font_size:10) {
  font-size: $font_size + px;
  font-size: get_vw($font_size);

  @media #{$sp} {
    font-size: get_vw($font_size,750);
  }

  // @media #{$max} {
  //   font-size: $font_size + px;
  // }
}

$properties: (
  "p": "padding",
  "pt": "padding-top",
  "pl": "padding-left",
  "pb": "padding-bottom",
  "pr": "padding-right",
  "m": "margin",
  "mt": "margin-top",
  "ml": "margin-left",
  "mb": "margin-bottom",
  "mr": "margin-right",
  "w": "width",
  "h": "height",
  "t": "top",
  "l": "left",
  "b": "bottom",
  "r": "right",
) !default;

@mixin props($property, $size, $parent_width:1394) {
  @if($property=="h") {
    height: ($size / $parent_width) * 100vw;
    // max-height: $size + px;
    @media #{$sp} {
      $parent_width:750;
      height: ($size / $parent_width) * 100vw;
    }
  }
  @else if($property=="w") {
    width: ($size / $parent_width) * 100vw;
    // max-width: $size + px;
    @media #{$sp} {
      $parent_width:750;
      width: ($size / $parent_width) * 100vw;
    }
  }
  @else {
    #{map-get($properties, $property)}: ($size / $parent_width) * 100vw;
    // @media #{$max} {
    //   #{map-get($properties, $property)}: $size + px;
    // }
    @media #{$sp} {
      $parent_width:750;
      #{map-get($properties, $property)}: ($size / $parent_width) * 100vw;
    }
  }
}
