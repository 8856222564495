.teacher {
  padding-top: 104px;
  @media #{$pc} {
    padding-top: 140px;
  }
  &-contents {
    @media #{$pc} {
      max-width: calc(100% - 156px);
    }
  }
}
#fixed-head {
  @media #{$pc} {
    max-width: calc(100% - 180px);
  }
}
#result-content,
#problem,
#answer {
  @media #{$pc} {
    max-height: calc((var(--vh, 1vh) * 100) - var(--headHeight, 1vh) - 62px);
  }
}