/*
  ----------------
  文章のスタイル設定
  ----------------
*/

body {
  font-family: $font-family;
  color: $base;
}

.font-roboto {
  font-family: 'Roboto';
}

.font-dinot {
  font-family: 'DINOT';
}
