.header {
  &-menu {
    background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    span {
      transition: all .3s ease;
      left: 0;
      right: 0;
      margin: auto;
      width: 22px;
      height: 3px;
      &:nth-of-type(1) {
        top: 13px;
      }
      &:nth-of-type(2) {
        top: 20px;
      }
      &:nth-of-type(3) {
        top: 27px;
      }
    }
    p {
      position: absolute;
      top: 33px;
      left: 0;
      right: 0;
      margin: auto;
      width: 58px;
      text-align: center;
    }
    &.is-open {
      span {
        &:nth-child(1) {
          transform: translateY(4px) rotate(-45deg);
          top: 18px;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translateY(-8px) rotate(45deg);
          top: 30px;
        }
      }
    }
  }
  &-nav {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    top: 90px;
    left: 20px;
    width: calc(100% - 40px);
    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      width: 23px;
      height: 23px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      background: #fff;
      top: -5px;
      right: 20px;
      transform: rotate(45deg) skew(20deg, 20deg);
    }
    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 10px;
    }
    &>* {
      position: relative;
      z-index: 3;
    }
  }
}