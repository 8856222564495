/*
  ----------------
  ユーティリティー
  ----------------
*/
.container {
  @include container;
}
.clearfix {
  @include clearfix;
}
/*
  ----------------
  レスポンシブ用のユティリティー
  ----------------
*/
.sp-only {
  @media #{$pc} {
    display: none !important;
  }
}
.pc-only {
  @media #{$sp} {
    display: none !important;
  }
}
.ml-a {
  margin-left: auto;
}
.mr-a {
  margin-right: auto;
}
.mx-a {
  margin-left: auto;
  margin-right: auto;
}
.my-a {
  margin-top: auto;
  margin-bottom: auto;
}
.min-h-screen {
  min-height: calc(var(--vh, 1vh) * 100);
}