.fukidasi {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}
.fukidasi::before {
  content: "";
  width: 29px;
  height: 24px;
  display: block;
  background: url('/assets/img/common/fukidasi.svg');
  position: absolute;
  bottom: -18px;
  right: 75px;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
}