@charset "UTF-8";
/*
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap" rel="stylesheet">
*/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&family=Roboto:wght@900&display=swap");
@font-face {
  font-family: 'DINOT';
  font-display: swap;
  src: url("../font/DINOT-Bold.otf") format("opentype"), url("../font/DINOT-Bold.woff") format("woff"), url("../font/DINOT-Bold.woff2") format("woff2"); }

* {
  box-sizing: border-box; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; }

body {
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
  font-size: inherit; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

img {
  border: 0;
  max-width: 100%;
  height: auto;
  backface-visibility: hidden;
  vertical-align: bottom; }

ul,
ol,
dl,
dt,
dd {
  padding: 0;
  margin: 0; }

li, ol {
  list-style: none; }

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
  cursor: pointer; }

p {
  margin-top: 0;
  margin-bottom: 0; }

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer; }

button:focus {
  outline: 0; }

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0; }

[role="button"] {
  color: inherit;
  cursor: default;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: pre;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

@media all and (min-width: 1025px) {
  a[href^=tel] {
    pointer-events: none; } }

@tailwind base;
@tailwind components;
@tailwind utilities;
/*
  ----------------
  Base styles
  ----------------
*/
html {
  font-feature-settings: "palt"; }

/*
  ----------------
  選択する際の色設定
  ----------------
*/
/*
  ----------------
  文章のスタイル設定
  ----------------
*/
body {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  color: #333; }

.font-roboto {
  font-family: 'Roboto'; }

.font-dinot {
  font-family: 'DINOT'; }

/*
  ----------------
  ユーティリティー
  ----------------
*/
.container {
  max-width: 1240px;
  position: relative;
  margin: auto;
  padding: 0 20px; }
  @media all and (max-width: 767px) {
    .container {
      padding: 0 15px; } }

.clearfix::after {
  content: "";
  display: table;
  clear: both; }

/*
  ----------------
  レスポンシブ用のユティリティー
  ----------------
*/
@media all and (min-width: 1025px) {
  .sp-only {
    display: none !important; } }

@media all and (max-width: 767px) {
  .pc-only {
    display: none !important; } }

.ml-a {
  margin-left: auto; }

.mr-a {
  margin-right: auto; }

.mx-a {
  margin-left: auto;
  margin-right: auto; }

.my-a {
  margin-top: auto;
  margin-bottom: auto; }

.min-h-screen {
  min-height: calc(var(--vh, 1vh) * 100); }

/*
  ----------------
  Animations
  ----------------
*/
.hide {
  display: none; }

.header-menu {
  background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1); }
  .header-menu span {
    transition: all .3s ease;
    left: 0;
    right: 0;
    margin: auto;
    width: 22px;
    height: 3px; }
    .header-menu span:nth-of-type(1) {
      top: 13px; }
    .header-menu span:nth-of-type(2) {
      top: 20px; }
    .header-menu span:nth-of-type(3) {
      top: 27px; }
  .header-menu p {
    position: absolute;
    top: 33px;
    left: 0;
    right: 0;
    margin: auto;
    width: 58px;
    text-align: center; }
  .header-menu.is-open span:nth-child(1) {
    transform: translateY(4px) rotate(-45deg);
    top: 18px; }
  .header-menu.is-open span:nth-child(2) {
    opacity: 0; }
  .header-menu.is-open span:nth-child(3) {
    transform: translateY(-8px) rotate(45deg);
    top: 30px; }

.header-nav {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  top: 90px;
  left: 20px;
  width: calc(100% - 40px); }
  .header-nav::before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 23px;
    height: 23px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    background: #fff;
    top: -5px;
    right: 20px;
    transform: rotate(45deg) skew(20deg, 20deg); }
  .header-nav::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 10px; }
  .header-nav > * {
    position: relative;
    z-index: 3; }

/*
  ----------------
  Headings
  ----------------
*/
/*
  ----------------
  パンくずのスタイル設定
  ----------------
*/
/*
  ----------------
  ボタン
  ----------------
*/
.button {
  background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 3px; }

.tab {
  border: 1px solid #DBDBDB;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%); }
  .tab.current {
    border: none;
    box-shadow: none;
    font-weight: bold;
    background: #52B2BA;
    color: #fff;
    position: relative; }
    .tab.current::after {
      position: absolute;
      bottom: -9px;
      left: 0;
      right: 0;
      margin: auto;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 13px 9px 0 9px;
      border-color: #52B2BA transparent transparent transparent; }
    .tab.current img {
      opacity: 0;
      display: none; }

.fade-in {
  transition: opacity 0.3s, visibility 0s ease 0.3s;
  opacity: 0;
  visibility: hidden; }
  .fade-in.is-show {
    transition-delay: 0s;
    opacity: 1;
    visibility: visible; }

@media all and (min-width: 1025px) {
  #fixed-btn {
    width: calc(100% - 180px); } }

.icon-eye {
  right: 16px;
  bottom: 22px;
  cursor: pointer; }
  @media all and (max-width: 767px) {
    .icon-eye {
      bottom: 16px; } }
  .icon-eye::after {
    content: "";
    display: block;
    background: url(/assets/img/common/icon-eye.svg) center/contain no-repeat;
    width: 20px;
    height: 14px; }
    @media all and (max-width: 767px) {
      .icon-eye::after {
        width: 14px;
        height: 10px; } }
  .icon-eye::before {
    content: "";
    display: none;
    width: 4px;
    height: 20px;
    background: #fff;
    border-left: 2px solid #d4d4d4;
    transform: rotate(-45deg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }
    @media all and (max-width: 767px) {
      .icon-eye::before {
        border-left: 1px solid #d4d4d4;
        width: 2px;
        height: 15px; } }
  .icon-eye.is-show::before {
    display: block; }

.icon-qrcode {
  width: 20px;
  height: 20px;
  right: 16px;
  bottom: 18%;
  cursor: pointer;
  background: url(/assets/img/common/icon-qrcode.svg) 50%/contain no-repeat; }

.shadow {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15); }

.fukidasi {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25); }

.fukidasi::before {
  content: "";
  width: 29px;
  height: 24px;
  display: block;
  background: url("/assets/img/common/fukidasi.svg");
  position: absolute;
  bottom: -18px;
  right: 75px;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25)); }

.login-form {
  width: 700px;
  height: 700px;
  background: url(/assets/img/common/hexagon.svg) center/contain no-repeat; }
  @media all and (max-width: 767px) {
    .login-form {
      width: 100%;
      height: auto;
      background: #fff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15); } }

.login-btn {
  background: linear-gradient(180deg, #52B2BA 0%, #3C9DA5 100%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }

.student {
  padding-top: 160px; }
  @media all and (max-width: 767px) {
    .student {
      padding-top: 118px; } }

.test {
  padding-top: 118px; }
  @media all and (max-width: 767px) {
    .test {
      padding-top: 107px; } }

.test-problem {
  flex-basis: 35%;
  /* スクロールバーの幅と高さを設定する */
  /* スクロールバーの形を設定する */
  /* スクロールバーの色を設定する */ }
  @media all and (max-width: 767px) {
    .test-problem {
      max-height: 87px; } }
  .test-problem::-webkit-scrollbar {
    width: 6px; }
  .test-problem::-webkit-scrollbar-thumb {
    border-radius: 30px; }
  .test-problem::-webkit-scrollbar-thumb {
    --bg-opacity: 1;
    background-color: #666; }

.test-choices {
  flex-basis: 65%;
  counter-reset: number 0;
  /* スクロールバーの幅と高さを設定する */
  /* スクロールバーの形を設定する */
  /* スクロールバーの色を設定する */ }
  @media all and (max-width: 767px) {
    .test-choices {
      max-height: 220px; } }
  .test-choices::-webkit-scrollbar {
    width: 6px; }
  .test-choices::-webkit-scrollbar-thumb {
    border-radius: 30px; }
  .test-choices::-webkit-scrollbar-thumb {
    --bg-opacity: 1;
    background-color: #666; }
  .test-choices button {
    display: flex;
    align-items: baseline; }
    .test-choices button::before {
      counter-increment: number 1;
      content: counter(number) ".";
      margin-right: 1.5rem; }
      @media all and (max-width: 767px) {
        .test-choices button::before {
          margin-right: 5px; } }

.teacher {
  padding-top: 104px; }
  @media all and (min-width: 1025px) {
    .teacher {
      padding-top: 140px; } }
  @media all and (min-width: 1025px) {
    .teacher-contents {
      max-width: calc(100% - 156px); } }

@media all and (min-width: 1025px) {
  #fixed-head {
    max-width: calc(100% - 180px); } }

@media all and (min-width: 1025px) {
  #result-content,
  #problem,
  #answer {
    max-height: calc((var(--vh, 1vh) * 100) - var(--headHeight, 1vh) - 62px); } }
