// Breakpoint設定

$sp: "all and (max-width : 767px)";
$tab: "all and (min-width : 768px) and (max-width : 1024px)";
$pc: "all and (min-width : 1025px)";
$max: "all and (min-width: 1394px)";

/*
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap" rel="stylesheet">
*/

$font-family: 'Noto Sans JP','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','游ゴシック','Yu Gothic','游ゴシック体','YuGothic','ＭＳ Ｐゴシック','MS PGothic',sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&family=Roboto:wght@900&display=swap');

@font-face {
  font-family: 'DINOT';
  font-display: swap;
  src: url('../font/DINOT-Bold.otf') format('opentype'),
      url('../font/DINOT-Bold.woff') format('woff'),
      url('../font/DINOT-Bold.woff2') format('woff2');
}

// カラー
$base:#333;

// テキストカラー


// コンテナー設定

$container: 1200px + 40px;

// グリッド設定

$gutter: 20px;
$small-gutter: 10px;

// z-index

$z-map: (
  schedule,
  contact,
  about,
  main-visual,
  footer-obj
);

@function z($name) {
  @return index($z-map, $name);
}