.icon-eye {
  right: 16px;
  bottom: 22px;
  cursor: pointer;
  @media #{$sp} {
    bottom: 16px;
  }
  &::after {
    content: "";
    display: block;
    background: url(/assets/img/common/icon-eye.svg) center/contain no-repeat;
    width: 20px;
    height: 14px;
    @media #{$sp} {
      width: 14px;
      height: 10px;
    }
  }
  &::before {
    content: "";
    display: none;
    width: 4px;
    height: 20px;
    background: #fff;
    border-left: 2px solid #d4d4d4;
    transform: rotate(-45deg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    @media #{$sp} {
      border-left: 1px solid #d4d4d4;
      width: 2px;
      height: 15px;
    }
  }
  &.is-show {
    &::before {
      display: block;
    }
  }
}

.icon-qrcode {
  width: 20px;
  height: 20px;
  right: 16px;
  bottom: 18%;
  cursor: pointer;
  background: url(/assets/img/common/icon-qrcode.svg) 50% / contain no-repeat;
}
