.student {
  padding-top: 160px;
  @media #{$sp} {
    padding-top: 118px;
  }
}
.test {
  padding-top: 118px;
  @media #{$sp} {
    padding-top: 107px;
  }
}
.test-problem {
  flex-basis: 35%;
  @media #{$sp} {
    max-height: 87px;
  }
  /* スクロールバーの幅と高さを設定する */
  &::-webkit-scrollbar {
    width: 6px;
  }
  /* スクロールバーの形を設定する */
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
  }
  /* スクロールバーの色を設定する */
  &::-webkit-scrollbar-thumb {
    --bg-opacity: 1;
    background-color: #666;
  }
}
.test-choices {
  flex-basis: 65%;
  counter-reset: number 0;
  @media #{$sp} {
    max-height: 220px;
  }
  /* スクロールバーの幅と高さを設定する */
  &::-webkit-scrollbar {
    width: 6px;
  }
  /* スクロールバーの形を設定する */
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
  }
  /* スクロールバーの色を設定する */
  &::-webkit-scrollbar-thumb {
    --bg-opacity: 1;
    background-color: #666;
  }
  button {
    display: flex;
    align-items: baseline;
    &::before {
      counter-increment: number 1;
      content: counter(number) ".";
      margin-right: 1.5rem;
      @media #{$sp} {
        margin-right: 5px;
      }
    }
  }
}
