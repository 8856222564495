/*
  ----------------
  ボタン
  ----------------
*/
.button {
  background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.tab {
  border: 1px solid #DBDBDB;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%);
  &.current {
    border: none;
    box-shadow: none;
    font-weight: bold;
    background: #52B2BA;
    color: #fff;
    position: relative;
    &::after {
      position: absolute;
      bottom: -9px;
      left: 0;
      right: 0;
      margin: auto;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 13px 9px 0 9px;
      border-color: #52B2BA transparent transparent transparent;
    }
    img {
      opacity: 0;
      display: none;
    }
  }
}